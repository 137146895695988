<template>
  <div class="app-footer font-size-sm text-black-50">
    <div>
      © 2020 - CloudStick LLC - created by
      <a
        href="https://cloudstick.io"
        title="UiFort.com"
        target="_blank"
      >CloudStick.io</a>
    </div>
    <div>
      <ul class="nav nav-justified">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0);">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0);">License</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="javascript:void(0);">Changelog</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {}
};
</script>
