import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/dashboard",
    meta: { layout: "profile-page" },
    name: "",
    component: () => import("../components/views/dashboards/maindashtest.vue"),
  },
  {
    path: "/login",
    meta: { layout: "examples" },
    name: "login",
    component: () => import("../components/user-pages/login.vue"),
  },
  {
    path: "/reset-password",
    meta: { layout: "examples" },
    name: "resetpassword",
    component: () => import("../components/user-pages/reset-password.vue"),
  },
  {
    path: "/passwordchanged",
    meta: { layout: "examples" },
    name: "passwordchanged",
    component: () => import("../components/user-pages/passwordchanged.vue"),
  },
  {
    path: "/register",
    meta: { layout: "examples" },
    name: "createaccount",
    component: () => import("../components/user-pages/createaccount.vue"),
  },
  {
    path: "/email-accounts",
    name: "emails",
    meta: { layout: "profile-page" },
    component: () =>
      import("../components/views/email/emails.vue"),
  },
  {
    path: "/email-authentication",
    name: "email-authentication",
    meta: { layout: "profile-page" },
    component: () =>
      import("../components/views/email/email-authentication.vue"),
  },
  {
    path: "/email-delivery",
    name: "email-delivery",
    meta: { layout: "profile-page" },
    component: () =>
      import("../components/views/email/email-delivery.vue"),
  },
  {
    path: "/database-list",
    name: "database-list",
    meta: { layout: "profile-page" },
    component: () => import("../components/views/databases/database-list.vue"),
  },
  {
    path: "/database-users",
    name: "database-users",
    meta: { layout: "profile-page" },
    component: () => import("../components/views/databases/database-users.vue"),
  },
  {
    path: "/mysql",
    name: "MySQL Database new",
    meta: { layout: "profile-page" },
    component: () => import("../components/views/databases/mysql/mysql.vue"),
  },


  {
    path: "/mysqlsecurity",
    name: "MySQL security",
    meta: { layout: "profile-page" },
    component: () => import("../components/views/databases/mysql/mysqlsecurity.vue"),
  },
  {
    path: "/mysqldatabasesred",
    name: "mysqldatabasesred",
    meta: { layout: "profile-page" },
    component: () => import("../components/views/databases/mysql/mysqldatabasesred.vue"),
  },
  {
    path: "/mysqldatabasewizard",
    name: "mysqldatabasewizard",
    meta: { layout: "profile-page" },
    component: () => import("../components/views/databases/mysql/mysqldatabasewizard.vue"),
  },
  {
    path: '/',
    name: 'home',
    meta: { layout: "profile-page" },

    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  
]

const router = new VueRouter({

  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  base: process.env.BASE_URL,
  routes
})

export default router
